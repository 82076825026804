import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import {
  hideLogin,
  hideLoginModal,
  setShowForgotSuccessMessage,
  showForgot,
  showLoginModal,
  showOtp,
} from '@/store/features/loginSlice';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { userService } from '@/services/userService';
import { toast } from 'react-toastify';
import { RootState } from '@/store';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
// import WsStaticImage from '@/components/common/WsStaticImage';
const WsButton = dynamic(() => import('@/components/common/WsButton'));
interface Props {
  isRedirect?: boolean;
  // Define the props for your component here
}
const ValidateFields = (props: any) => {
  const [oldVales, setOldValues] = useState({ email: '', password: '' });
  const { values, validateField }: { values: any; validateField: any } =
    useFormikContext();
  useEffect(() => {
    if (values.email && values.email != oldVales.email) {
      validateField('email');
    }
    if (values.password && values.password != oldVales.password) {
      validateField('password');
    }
    setOldValues({ email: values.email, password: values.password });
    props.setError('');
  }, [values.email, values.password]);
};

const LoginForm: React.FC<Props> = ({
  isRedirect,
  /* Destructure props here */
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { showForgotMessage } = useSelector(
    (state: RootState) => state.loginModal
  );
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [emailText, setEmail] = useState(false);
  const [error, setError] = useState('');

  const showOtpBlock = () => {
    dispatch(showLoginModal());
    dispatch(hideLogin());
    dispatch(showOtp());
  };

  const handleShowHidePassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const showForgotPassword = () => {
    dispatch(showLoginModal());
    dispatch(hideLogin());
    dispatch(showForgot());
  };
  useEffect(() => {
    if (showForgotMessage) {
      setTimeout(() => dispatch(setShowForgotSuccessMessage(false)), 3000);
    }
  }, [showForgotMessage]);
  useEffect(() => {
    isDisable &&
      setTimeout(() => {
        setDisable(false);
      }, 5000);
  }, [isDisable]);

  return (
    <div className={styles['register-account']}>
      <div id="login_popup_wrapper" className={styles['login-popup-section']}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(formData, { setErrors, resetForm }) => {
            if (isDisable) return;
            setLoading(true);
            setDisable(true);
            const txt = formData.email;
            let flag = true;
            if (emailText) {
              const match = txt.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
              if (!match) {
                flag = false;
                setErrors({ email: 'Please provide valid email' });
              }
            } else if (txt?.toString().length !== 10) {
              flag = false;
              setErrors({ email: 'Please provide valid mobile no.' });
            }
            if (flag) {
              return userService
                .login(formData)
                .then((data: any) => {
                  setLoading(false);
                  if (data.code == 200) {
                    if (data?.two_factor_login) {
                      showOtpBlock();
                    } else {
                      toast.success('Logged-In');
                    }
                    dispatch(hideLoginModal());
                    resetForm();
                    isRedirect && router.push('/');
                  } else {
                    setLoading(false);
                    setError(data.message || 'Something went wrong!!');
                  }
                })
                .catch((error) => {
                  setLoading(false);
                  setError(error.message || 'Something went wrong!!');
                });
            } else setLoading(false);
          }}
          validationSchema={yup.object({
            email: yup.string().trim().required('This field is required'),
            password: yup.string().trim().required('This field is required'),
          })}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form
              autoCapitalize="off"
              className={styles['field__form']}
              id="login_form_pop"
            >
              {/* @ts-expect-error Server Component */}
              <ValidateFields setError={setError} />
              <input
                type="hidden"
                className={styles['tokinput']}
                name="token"
                value=""
              />
              <div className={styles['heading']}>
                Sign in to your Account
                <p>To track your orders, manage your account and more.</p>
                <p
                  id="message"
                  className={`${styles['cart_error']} ${styles['warning']} `}
                  style={{ display: 'block', color: 'red' }}
                >
                  {error}
                </p>
                {showForgotMessage && (
                  <p id="message" style={{ color: 'green' }}>
                    New password has been Created successfully. Now you can
                    login with you new password
                  </p>
                )}
              </div>
              <div
                className={`${styles['box']} ${styles['field']} ${values.email ? styles['field--not-empty'] : ''}`}
              >
                <label
                  className={`${styles['field__label']} ${styles['label1']}`}
                >
                  {emailText ? 'Email Id' : 'Mobile No'}
                </label>
                <Field
                  autoComplete=""
                  type="text"
                  id="login_email"
                  name="email"
                  placeholder="Email ID/ Mobile No"
                  className={`${styles['field__input']}`}
                  onChange={(e: any) => {
                    const text = e.target.value.trim();
                    if (text?.length === 1) {
                      setEmail(!Number(text));
                      handleChange('email')(text);
                    } else if (emailText || !Number(text?.charAt(0))) {
                      handleChange('email')(text);
                      !emailText && setEmail(true);
                    } else {
                      const numericValue = text.replace(/[^0-9]/g, '');
                      numericValue.length < 11 &&
                        handleChange('email')(numericValue);
                    }
                  }}
                />
                {errors.email && touched.email && (
                  <p
                    id="reg_form_email"
                    className={`${styles['error-block']} ${styles['small']}`}
                  >
                    {errors.email}
                  </p>
                )}
              </div>
              <div
                className={`${styles['box']} ${styles['field']} ${values.password ? styles['field--not-empty'] : ''}`}
                id="pass"
              >
                <label className={`${styles['field__label']}`}>Password</label>
                <Field
                  autoComplete="new-password"
                  type={isShowPassword ? 'text' : 'password'}
                  maxLength={20}
                  id="password"
                  className={`${styles['field__input']}`}
                  placeholder="Password"
                  name="password"
                />
                <span
                  className={`${styles['hide-toggle']}`}
                  onClick={handleShowHidePassword}
                >
                  {isShowPassword ? 'Hide' : 'Show'}
                </span>
                {errors.password && touched.password && (
                  <p
                    id="reg_form_email"
                    className={`${styles['error-block']} ${styles['small']}`}
                  >
                    {errors.password}
                  </p>
                )}
              </div>

              <span
                onClick={showForgotPassword}
                className={styles['forgot-pass']}
              >
                Forgot Password?
              </span>

              <input type="hidden" name="redirect2" value="" />
              <input
                type="hidden"
                name="pagesource"
                className={styles['register_pagesource']}
                value="onload"
              />
              <div className={styles['signup-block']}>
                <p>
                  Don't have an account?
                  <Link href={'/register'}>Register Here</Link>
                </p>
                <WsButton
                  type="submit"
                  className={styles['sign-btn']}
                  id="login_submit_btn"
                  isLoading={loading}
                >
                  {/* <i className={styles['loader']}>
                    <WsStaticImage
                      src={'/images/spinner-solid.png'}
                      alt="loader"
                      width={16}
                      height={16}
                    />
                  </i> */}
                  Log In
                </WsButton>

                {/* <div className={styles['sign-or-line']}>
                  <p>Or</p>{' '}
                </div> */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginForm;
